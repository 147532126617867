.tag-filter {
    padding: 8px 15px;
    margin-bottom: 15px;
    height: 32px;

    position: sticky;
    top: 65px;
    z-index: 10;

    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    transition:
        height 0.2s ease-in-out,
        padding 0.2s ease-in-out,
        margin 0.2s ease-in-out;
}

.tag-filter .tags {
    overflow-x: auto;
    flex-grow: 1;
    flex-basis: 0px;
    white-space: nowrap;
}

.tag-filter.empty {
    height: 0px;

    padding-top: 0;
    padding-bottom: 0;

    margin-bottom: 0;
}

.tag-filter p {
    margin: 0;
    margin-right: 5px;

    color: #555;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

@media (max-width: 540px) {
    .tag-filter {
        top: 100px;
    }
}
