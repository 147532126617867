/***** Project Cards *****/
a.blog-card {
    width: 95%;
    max-width: 800px;
    padding: 40px 50px;
    padding-bottom: 0;
    margin-top: 15px;
    margin-bottom: 40px;

    text-decoration: none;
    color: unset;
    display: block;

    border-radius: 0px 8px 8px 0px;

    background-color: white;
    border-left: 8px solid hsl(200, 60%, 30%);
    box-shadow: 1px 1px 3px hsla(200, 10%, 10%, 0.3);
}

.blog-card.loading {
    font-size: 24px;
    text-align: center;
    margin: 50px auto;
    font-family: 'EB Garamond', serif;
    color: hsl(200, 10%, 30%);
}

/*** Header ***/
.blog-title {
    font-family: Raleway, sans-serif;
    font-size: 30px;
    line-height: 1.2;
    color: hsl(200, 100%, 30%);
    font-weight: 500;
    text-overflow: ellipsis;

    margin: 0;
    margin-left: -3px;
}

.blog-subtitle {
    margin-top: 0;
    margin-bottom: 10px;
    font-family: Roboto, sans-serif;
    color: hsl(200, 10%, 55%);
    font-size: 12px;

    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
}

.blog-subtitle .date {
    margin-right: 8px;
}

.blog-subtitle .read-time {
    margin-left: 8px;
}

/*** Content ***/
.blog-info {
    font-size: 18px;
    font-family: 'EB Garamond', serif;
    line-height: 1.5;
    text-decoration: none;
    color: hsl(200, 10%, 10%);

    display: block;
    margin-bottom: 20px;
    padding-top: 1px;
    position: relative;

    cursor: pointer;

    max-height: 350px;
    overflow: hidden;
}

.blog-info img {
    display: block;
    max-width: 100%;
    margin: 10px auto;
}

.blog-info a.reference {
    display: none;
}

.blog-info-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, hsla(200, 10%, 100%, 0), hsl(200, 10%, 100%, 0.98) 85%, hsl(200, 10%, 100%));
    z-index: 1;
}


/* Tags */
.blog-tags {
    display: flex;
    flex-direction: row;

    align-items: center;
    flex-wrap: wrap;

    margin-top: 8px;
    margin-bottom: 5px;
}

.blog-tag {
    background-color: hsl(200, 30%, 97%);

    color: hsl(200, 20%, 50%);
    font-size: 12px;
    padding: 3px 8px;
    letter-spacing: 0.05em;
    margin-top: 6px;
    margin-right: 8px;

    white-space: nowrap;

    cursor: pointer;

    transition: background-color 0.15s ease-in-out;
}

.blog-tag:hover {
    background-color: hsl(200, 20%, 94%);
}


/***** Media Queries *****/
@media (max-width: 1000px) {
    a.blog-card {
        padding: 25px 28px;
        padding-bottom: 0;
        border-left: 6px solid hsl(200, 60%, 30%);
        
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .blog-title {
        font-size: 24px;
    }

    .blog-tags {
        margin-top: 6px;
    }

    .blog-tag {
        padding: 2px 6px;
    }
}

@media (max-width: 600px) {
    a.blog-card {
        margin-top: 0;
        margin-bottom: -20px;
        border: unset;

        box-shadow: unset;
        background-color: unset;
    }

    .blog-title {
        font-size: 20px;
        margin-left: -2px;
    }

    p.blog-subtitle {
        margin-bottom: 8px;
    }

    .blog-tags {
        display: none;
    }

    .blog-info {
        padding-top: 0;
        font-size: 16px;

        max-height: 200px;
    }

    .blog-info-cover {
        background-image: linear-gradient(to bottom, hsla(200, 10%, 96%, 0), hsl(200, 10%, 96%, 0.98) 85%, hsl(200, 10%, 96%));
    }
}


@media print {
    .blog-card {
        page-break-inside: avoid;
    }

    .blog-title, .blog-subtitle, .blog-info {
        color: black;
    }

    .blog-tag {
        background-color: white;
        border: 1px solid black;
        box-sizing: border-box;
        color: black;
    }

    .blog-info-cover {
        display: none;
    }
}
