/*** Navigation Bar **/
#navigation-bar {
    background-color: hsla(200, 30%, 5%, 0.85);
    backdrop-filter: blur(5px);
    box-shadow: none;

    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;

    display: flex;
    flex-direction: column;

    transition:
        background-color 0.15s ease-in-out;
}

html[data-scroll='0'] #navigation-bar {
    backdrop-filter: none;
    background-color: transparent;
}

#main-bar {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 56px;
}

#navigation-bar a {
    text-decoration: none;
}


/*** Logo ***/
#main-logo-container {
    display: block;

    margin-left: 25px;
    margin-right: 45px;
}

#main-logo {
    font-family: 'Fira Sans Condensed', sans-serif;
    color: white;

    font-size: 30px;
    line-height: 1;
    text-decoration: none;

    transition: color 0.2s ease-in-out;
}

#main-logo:hover {
    color: hsl(200, 5%, 95%);
}


/*** Tabs ***/
.navigation-tabs {
    display: flex;
    flex-direction: row;
}

.nav-tab {
    flex: 1 1 0;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-family: 'Raleway';
    font-size: 18px;

    border-bottom: 3px solid transparent;
    transition: background-color 0.15s ease-in-out,
        border-bottom 0.15s ease-in-out;
}

html:not([data-scroll='0']) .nav-tab.selected {
    background-color: hsla(200, 10%, 90%, 0.1);
    border-bottom: 3px solid hsla(200, 90%, 50%, 0.5);
}

html:not([data-scroll='0']) .nav-tab:hover:not(.selected) {
    background-color: hsla(200, 10%, 90%, 0.05);
}

.desktop.navigation-tabs {
    width: 375px;
    height: 100%;
}

.desktop.navigation-tabs .nav-tab {
    border-top: 3px solid transparent;
}

.mobile.navigation-tabs {
    display: none;
}


/*** Social Icons ***/
#social-buttons {
    display: flex;
    flex-direction: row;

    position: absolute;
    right: 10px;

}

#social-buttons > a {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.social-icon {
    width: 30px;
    margin-right: 20px;
}


/***** Media Queries *****/
@media (max-width: 700px) {
    .desktop.navigation-tabs {
        width: 300px;
    }
}

@media (max-width: 630px) {
    .mobile.navigation-tabs {
        display: flex;
        flex-direction: row;
        height: 40px;
    }

    html[data-scroll='0'] .mobile.navigation-tabs {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .desktop.navigation-tabs {
        display: none;
    }

    #main-bar {
        height: 44px;
    }

    #main-logo-container {
        margin-left: 10px;
    }

    #main-logo {
        font-size: 24px;
    }

    #social-buttons {
        right: 0;
    }

    .social-icon {
        width: 25px;
        margin-right: 10px;
    }
}

@media (max-height: 400px) {
    #main-bar {
        height: 44px;
    }
}

@media print {
    #navigation-bar {
        position: static;
        background-color: transparent;
    }

    #main-bar {
        height: auto;
    }

    #main-logo {
        color: black;
        font-size: 14px;
    }

    .navigation-tabs {
        display: none !important;
    }

    #social-buttons {
        display: none;
    }
}
