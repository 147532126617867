html, body {
    background-color: hsl(200, 10%, 96%);
}

#content-container {
    background-color: hsl(200, 10%, 96%);
    z-index: 500;
    position: relative;

    padding: 40px;
}

.text-container {
    left: 0;
    right: 0;

    width: 600px;
    max-width: 85vw;
    margin: 0 auto;
}

.text-container > p {
    text-align: justify;

    margin-top: 0;
    font-family: 'EB Garamond', serif;
    font-size: 22px;
}


/* Media queries */
@media (max-width: 1000px) {
    #content-container {
        padding: 30px;
    }
}

@media (max-width: 700px) {
    .text-container > p {
        text-align: left;
        font-size: 20px;
    }
}

@media (max-width: 540px) {
    #content-container {
        padding: 15px;
    }

    .text-container {
        margin-top: 15px;
    }

    .text-container > p {
        text-align: left;
        font-size: 18px;
    }
}


@media print {
    #content-container {
        background-color: unset;
        padding: 0;
    }

    .text-container {
        margin-top: 0;
        width: unset;
        max-width: unset;
    }

    .text-container p {
        font-size: 18px;
    }

    html, body {
        background-color: unset;
    }
}
