/***** Project Cards *****/
.project-card.MuiPaper-root {
    place-self: stretch stretch;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    cursor: pointer;

    transition: all 0.2s ease-in-out;
}

.project-card:hover {
    transform: scale(1.03);
    box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
}

.project-info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.project-image {
    height: 200px;
}


/*** Header ***/
.project-header {
    display: flex;
    width: 100%;
}

h2.project-title {
    float: left;
    overflow-x: hidden;
    flex-grow: 1;

    font-size: 18px;
    margin: 0;
    color: hsl(200, 10%, 15%);
    font-weight: 500;

    white-space: nowrap;
    text-overflow: ellipsis;

    margin-right: 5px;
}

.project-date {
    float: right;
    text-align: right;
    padding-top: 3px;
    color: hsl(200, 20%, 40%);
}

/*** Content ***/
.project-info {
    flex-grow: 1;
    margin-bottom: 20px;
}


.project-info p {
    margin-top: 10px;
    margin-bottom: 0;

    line-height: 1.5;
    font-size: 15px;

    color: hsl(200, 10%, 30%);
}

a.project-info {
    text-decoration: none;
    color: black;
}

/*** Footer ***/
.project-footer {
    margin-top: auto;
    display: flex;
}

/* Tags */
.project-tags-container {
    /* Fill all allotted space */
    display: flex;
    flex-grow: 1;

    /* Positioning and element wrapping*/
    white-space: nowrap;
    margin-right: 10px;
    margin-top: 3px;

    /* Clip scrollbar in .project-tags */
    overflow: hidden;
    height: 32px;
}

.project-tag-label {
    padding-top: 9px;
}

.project-tags {
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;

    height: 60px; /* Push scrollbar out of view */
}

.project-tag.MuiButtonBase-root {
    margin-left: 6px;
}

/* Social Icons */
.project-social {
    display: inline-block;
    float: right;
    white-space: nowrap;
}

.project-social a {
    padding: 4px;
}

.project-social a img {
    width: 32px;
}


/***** Media Queries *****/
@media (max-width: 480px) {
    .project-header {
        display: inline-block;
    }

    .project-title {
        width: 100%;
    }

    .project-date {
        float: left;
        text-align: left;
        margin-top: 2px;
    }

    .project-card:hover {
        transform: none;
    }
}


@media print {
    .project-card {
        box-shadow: none !important;
        border: 1px solid black;
        box-sizing: border-box;
        page-break-inside: avoid;
    }

    .project-image {
        display: none !important;
    }

    .project-date span {
        color: black;
    }

    .project-tag-label {
        color: black !important;
    }

    .project-tag {
        background-color: white !important;
        border: 1px solid black !important;
        box-sizing: border-box;
    }

    .project-social {
        display: none;
    }
}
