#content-container.blog {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#blog-content {
    width: 95%;
    max-width: 700px;
    color: hsl(200, 10%, 15%);

    line-height: 1.5;
    font-family: 'EB Garamond', serif;
    font-size: 20px;
}

#blog-content .aux-info {
    font-size: 16px;
    color: hsl(200, 10%, 40%);
    margin: 0;
    text-align: center;
}

#blog-content .aux-info .date {
    margin-right: 3px;
}

#blog-content .aux-info .read-time {
    margin-left: 3px;
}

#blog-content img {
    max-width: 100%;
    margin: 25px auto;
    display: block;

    /* Alt-Text */
    color: hsl(200, 10%, 40%);
    text-align: center;
}

#blog-content h2, #blog-content h3, #blog-content h4 {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: hsl(200, 90%, 30%);
}

#blog-content code {
    color: hsl(200, 30%, 25%);
    font-size: 0.8em;

    margin: -0.2em 0.2em;
    padding: 0.15em 0.3em;

    background-color: hsl(200, 20%, 90%);
    border-radius: 0.3em;
    
}

#blog-content pre {
    font-size: 14px;
    line-height: 1.4;
}

#blog-content pre code {
    color: unset;
    font-size: unset;
    margin: unset;
    padding: unset;
    background-color: unset;
    border-radius: unset;
}

#blog-content .emphasis {
    text-align: center;
    background-color: hsl(200, 80%, 88%);
    border: 1px solid hsl(200, 80%, 85%);
    border-radius: 8px;
    padding: 15px 20px;
    font-size: 1.2em;
}

#blog-content .emphasis span.katex-display {
    margin: 0;
}

/** Lists **/
#blog-content ul, #blog-content ol {
    display: block;
    padding-left: 1.5em;
}

#blog-content ul.bullet-x, #blog-content ul.bullet-check {
    list-style-type: none;
}

#blog-content ul.bullet-x li::before {
    content: '❌';
    margin-left: -30px;
    margin-right: 10px;
}

#blog-content ul.bullet-check li::before {
    content: '✅';
    margin-left: -30px;
    margin-right: 10px;
}

/** Loading Icons **/
#blog-content .loading-placeholder {
    margin: 25px auto;
    text-align: center;
    color: hsl(200, 10%, 50%);
    font-size: 14px;
}

#blog-content .loading-error {
    text-align: center;
    color: hsl(350, 100%, 40%);
}


/***** Media Queries *****/
@media (max-width: 700px) {
    #blog-content {
        font-size: 18px;
    }

    #blog-content .aux-info {
        margin-top: 8px;
        font-size: 14px;
    }

    #blog-content .emphasis {
        padding: 10px 15px;
        font-size: 1.1em;
    }
}

@media print {
    #blog-content h2, #blog-content h3, #blog-content h4 {
        color: black;
    }

    #blog-content .aux-info {
        text-align: center;
        color: black;
    }

    #blog-content {
        color: black;
        font-size: 14px;
    }

    #blog-content a {
        color: black;
        text-decoration: none;
    }

    #blog-content .emphasis {
        background-color: white;
        border: 2px solid black;
    }

    #blog-content img {
        page-break-inside: avoid;
    }

    #blog-content code {
        padding: 0;
        margin: 0;
        background-color: none;
    }
}
