.banner-container {
    left: 0;
    right: 0;

    position: relative;
    height: 450px;
    z-index: -10;
}

.banner-container.thin {
    height: 220px;
}

html {
    perspective: 1000px;
    perspective-origin: center 225px;
}

.banner-background {
    width: 100%;
    height: 100%;

    /* Background Image */
    object-fit: cover;

    /* Parallax Scroll */
    z-index: -100;
    transform: translateZ(-1000px) scale(2.1);
}

.banner-container.thin .banner-background {
    transform: translateY(-80px) translateZ(-500px) scale(2.1);
}

.banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
        hsla(200, 20%, 10%, 0.3),
        hsla(200, 20%, 10%, 0.6)
    );
}

.banner-text {
    display: block;
    position: relative;

    margin: 0 40px;

    color: white;
    text-align: center;
}

.banner-text > h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 90px;
    margin: 0;
}

.banner-container.thin .banner-text > h1 {
    font-size: 44px;
}

.banner-text > p {
    font-family: 'Raleway';
    font-size: 28px;
    line-height: 1.4;
    margin-top: 0.8em;
}

.down-arrow {
    position: absolute;
    bottom: 30px;
    display: none;
}

.down-arrow img {
    width: 50px;
}

@keyframes down-arrow {
    from {transform: translateY(0);}
    to {transform: translateY(-10px);}
}

#banner-content-anchor {
    position: absolute;
    bottom: 84px;
}


/* Media Queries */

@media (max-width: 800px) {
    .banner-container {
        height: 350px;
    }

    .banner-container.thin {
        height: 300px;
    }

    html {
        perspective-origin: center 175px;
    }

    .banner-text > h1 {
        font-size: 54px;
    }

    .banner-container.thin .banner-text > h1 {
        font-size: 36px;
    }

    .banner-text > p {
        font-size: 20px;
    }
}

@media (max-width: 630px) {
    .banner-overlay {
        padding-top: 80px;
    }
}

@media (max-height: 600px) {
    .banner-container:not(.thin) {
        height: 100%;
    }

    html {
        perspective-origin: center;
    }

    .banner-container:not(.thin) .down-arrow {
        display: block;

        animation-name: down-arrow;
        animation-direction: alternate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
}


@media print {
    .banner-container {
        height: auto !important;
    }

    .banner-background {
        display: none !important;
    }

    .banner-overlay {
        background: none;
        background-color: unset;
        position: static;
        padding-top: 0;
    }

    .banner-text {
        color: black;
    }

    .banner-text > h1 {
        font-size: 48px;
    }

    .banner-container.thin .banner-text > h1 {
        font-size: 24px;
    }

    .banner-text > p {
        font-size: 18px;
        margin-top: 4px;
        margin-bottom: 30px;
    }

    .down-arrow {
        display: none !important;
    }
}
