@import url('https://fonts.googleapis.com/css?family=Raleway:400,700|EB+Garamond|Playfair+Display|Fira+Sans+Condensed|Roboto:400,500,600&display=swap');

::selection {
    color: hsl(200, 10%, 97%);
    background: hsla(200, 60%, 8%, 0.9);
}

::-moz-selection {
    color: hsl(200, 10%, 97%);
    background: hsla(200, 60%, 8%, 0.9);
}

html {
    -webkit-text-size-adjust: 100%;
}

html, body, #root {
    overflow: hidden;
}

html, body, #root, #page-container {
    max-height: 100vh;
    max-width: 100vw;
    height: 100%;
    width: 100%;
}

#page-container {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transform-style: preserve-3d;

    background-color: hsl(200, 10%, 96%);
}

body {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
}

@media print {
    html, body, #root, #page-container {
        overflow: visible;
        height: unset;
        width: unset;
        max-height: unset;
        max-width: unset;
        background-color: unset;
    }
}
