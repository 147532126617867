#project-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 15px;

}

@media (max-width: 400px) {
    #project-container {
        grid-template-columns: 1fr;
    }
}
