@import url(https://fonts.googleapis.com/css?family=Raleway:400,700|EB+Garamond|Playfair+Display|Fira+Sans+Condensed|Roboto:400,500,600&display=swap);
::-moz-selection {
    color: hsl(200, 10%, 97%);
    background: hsla(200, 60%, 8%, 0.9);
}

::selection {
    color: hsl(200, 10%, 97%);
    background: hsla(200, 60%, 8%, 0.9);
}

::-moz-selection {
    color: hsl(200, 10%, 97%);
    background: hsla(200, 60%, 8%, 0.9);
}

html {
    -webkit-text-size-adjust: 100%;
}

html, body, #root {
    overflow: hidden;
}

html, body, #root, #page-container {
    max-height: 100vh;
    max-width: 100vw;
    height: 100%;
    width: 100%;
}

#page-container {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;

    background-color: hsl(200, 10%, 96%);
}

body {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
}

@media print {
    html, body, #root, #page-container {
        overflow: visible;
        height: unset;
        width: unset;
        max-height: unset;
        max-width: unset;
        background-color: unset;
    }
}

/*** Navigation Bar **/
#navigation-bar {
    background-color: hsla(200, 30%, 5%, 0.85);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    box-shadow: none;

    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    transition:
        background-color 0.15s ease-in-out;
}

html[data-scroll='0'] #navigation-bar {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    background-color: transparent;
}

#main-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;

    height: 56px;
}

#navigation-bar a {
    text-decoration: none;
}


/*** Logo ***/
#main-logo-container {
    display: block;

    margin-left: 25px;
    margin-right: 45px;
}

#main-logo {
    font-family: 'Fira Sans Condensed', sans-serif;
    color: white;

    font-size: 30px;
    line-height: 1;
    text-decoration: none;

    transition: color 0.2s ease-in-out;
}

#main-logo:hover {
    color: hsl(200, 5%, 95%);
}


/*** Tabs ***/
.navigation-tabs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.nav-tab {
    -webkit-flex: 1 1;
            flex: 1 1;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    color: white;
    font-family: 'Raleway';
    font-size: 18px;

    border-bottom: 3px solid transparent;
    transition: background-color 0.15s ease-in-out,
        border-bottom 0.15s ease-in-out;
}

html:not([data-scroll='0']) .nav-tab.selected {
    background-color: hsla(200, 10%, 90%, 0.1);
    border-bottom: 3px solid hsla(200, 90%, 50%, 0.5);
}

html:not([data-scroll='0']) .nav-tab:hover:not(.selected) {
    background-color: hsla(200, 10%, 90%, 0.05);
}

.desktop.navigation-tabs {
    width: 375px;
    height: 100%;
}

.desktop.navigation-tabs .nav-tab {
    border-top: 3px solid transparent;
}

.mobile.navigation-tabs {
    display: none;
}


/*** Social Icons ***/
#social-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;

    position: absolute;
    right: 10px;

}

#social-buttons > a {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.social-icon {
    width: 30px;
    margin-right: 20px;
}


/***** Media Queries *****/
@media (max-width: 700px) {
    .desktop.navigation-tabs {
        width: 300px;
    }
}

@media (max-width: 630px) {
    .mobile.navigation-tabs {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        height: 40px;
    }

    html[data-scroll='0'] .mobile.navigation-tabs {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .desktop.navigation-tabs {
        display: none;
    }

    #main-bar {
        height: 44px;
    }

    #main-logo-container {
        margin-left: 10px;
    }

    #main-logo {
        font-size: 24px;
    }

    #social-buttons {
        right: 0;
    }

    .social-icon {
        width: 25px;
        margin-right: 10px;
    }
}

@media (max-height: 400px) {
    #main-bar {
        height: 44px;
    }
}

@media print {
    #navigation-bar {
        position: static;
        background-color: transparent;
    }

    #main-bar {
        height: auto;
    }

    #main-logo {
        color: black;
        font-size: 14px;
    }

    .navigation-tabs {
        display: none !important;
    }

    #social-buttons {
        display: none;
    }
}

.banner-container {
    left: 0;
    right: 0;

    position: relative;
    height: 450px;
    z-index: -10;
}

.banner-container.thin {
    height: 220px;
}

html {
    -webkit-perspective: 1000px;
            perspective: 1000px;
    -webkit-perspective-origin: center 225px;
            perspective-origin: center 225px;
}

.banner-background {
    width: 100%;
    height: 100%;

    /* Background Image */
    object-fit: cover;

    /* Parallax Scroll */
    z-index: -100;
    -webkit-transform: translateZ(-1000px) scale(2.1);
            transform: translateZ(-1000px) scale(2.1);
}

.banner-container.thin .banner-background {
    -webkit-transform: translateY(-80px) translateZ(-500px) scale(2.1);
            transform: translateY(-80px) translateZ(-500px) scale(2.1);
}

.banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 20px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    background: linear-gradient(
        hsla(200, 20%, 10%, 0.3),
        hsla(200, 20%, 10%, 0.6)
    );
}

.banner-text {
    display: block;
    position: relative;

    margin: 0 40px;

    color: white;
    text-align: center;
}

.banner-text > h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 90px;
    margin: 0;
}

.banner-container.thin .banner-text > h1 {
    font-size: 44px;
}

.banner-text > p {
    font-family: 'Raleway';
    font-size: 28px;
    line-height: 1.4;
    margin-top: 0.8em;
}

.down-arrow {
    position: absolute;
    bottom: 30px;
    display: none;
}

.down-arrow img {
    width: 50px;
}

@-webkit-keyframes down-arrow {
    from {-webkit-transform: translateY(0);transform: translateY(0);}
    to {-webkit-transform: translateY(-10px);transform: translateY(-10px);}
}

@keyframes down-arrow {
    from {-webkit-transform: translateY(0);transform: translateY(0);}
    to {-webkit-transform: translateY(-10px);transform: translateY(-10px);}
}

#banner-content-anchor {
    position: absolute;
    bottom: 84px;
}


/* Media Queries */

@media (max-width: 800px) {
    .banner-container {
        height: 350px;
    }

    .banner-container.thin {
        height: 300px;
    }

    html {
        -webkit-perspective-origin: center 175px;
                perspective-origin: center 175px;
    }

    .banner-text > h1 {
        font-size: 54px;
    }

    .banner-container.thin .banner-text > h1 {
        font-size: 36px;
    }

    .banner-text > p {
        font-size: 20px;
    }
}

@media (max-width: 630px) {
    .banner-overlay {
        padding-top: 80px;
    }
}

@media (max-height: 600px) {
    .banner-container:not(.thin) {
        height: 100%;
    }

    html {
        -webkit-perspective-origin: center;
                perspective-origin: center;
    }

    .banner-container:not(.thin) .down-arrow {
        display: block;

        -webkit-animation-name: down-arrow;

                animation-name: down-arrow;
        -webkit-animation-direction: alternate;
                animation-direction: alternate;
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
    }
}


@media print {
    .banner-container {
        height: auto !important;
    }

    .banner-background {
        display: none !important;
    }

    .banner-overlay {
        background: none;
        background-color: unset;
        position: static;
        padding-top: 0;
    }

    .banner-text {
        color: black;
    }

    .banner-text > h1 {
        font-size: 48px;
    }

    .banner-container.thin .banner-text > h1 {
        font-size: 24px;
    }

    .banner-text > p {
        font-size: 18px;
        margin-top: 4px;
        margin-bottom: 30px;
    }

    .down-arrow {
        display: none !important;
    }
}

html, body {
    background-color: hsl(200, 10%, 96%);
}

#content-container {
    background-color: hsl(200, 10%, 96%);
    z-index: 500;
    position: relative;

    padding: 40px;
}

.text-container {
    left: 0;
    right: 0;

    width: 600px;
    max-width: 85vw;
    margin: 0 auto;
}

.text-container > p {
    text-align: justify;

    margin-top: 0;
    font-family: 'EB Garamond', serif;
    font-size: 22px;
}


/* Media queries */
@media (max-width: 1000px) {
    #content-container {
        padding: 30px;
    }
}

@media (max-width: 700px) {
    .text-container > p {
        text-align: left;
        font-size: 20px;
    }
}

@media (max-width: 540px) {
    #content-container {
        padding: 15px;
    }

    .text-container {
        margin-top: 15px;
    }

    .text-container > p {
        text-align: left;
        font-size: 18px;
    }
}


@media print {
    #content-container {
        background-color: unset;
        padding: 0;
    }

    .text-container {
        margin-top: 0;
        width: unset;
        max-width: unset;
    }

    .text-container p {
        font-size: 18px;
    }

    html, body {
        background-color: unset;
    }
}

#project-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 15px;

}

@media (max-width: 400px) {
    #project-container {
        grid-template-columns: 1fr;
    }
}

/***** Project Cards *****/
.project-card.MuiPaper-root {
    place-self: stretch stretch;

    overflow: hidden;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    cursor: pointer;

    transition: all 0.2s ease-in-out;
}

.project-card:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
}

.project-info-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.project-image {
    height: 200px;
}


/*** Header ***/
.project-header {
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

h2.project-title {
    float: left;
    overflow-x: hidden;
    -webkit-flex-grow: 1;
            flex-grow: 1;

    font-size: 18px;
    margin: 0;
    color: hsl(200, 10%, 15%);
    font-weight: 500;

    white-space: nowrap;
    text-overflow: ellipsis;

    margin-right: 5px;
}

.project-date {
    float: right;
    text-align: right;
    padding-top: 3px;
    color: hsl(200, 20%, 40%);
}

/*** Content ***/
.project-info {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin-bottom: 20px;
}


.project-info p {
    margin-top: 10px;
    margin-bottom: 0;

    line-height: 1.5;
    font-size: 15px;

    color: hsl(200, 10%, 30%);
}

a.project-info {
    text-decoration: none;
    color: black;
}

/*** Footer ***/
.project-footer {
    margin-top: auto;
    display: -webkit-flex;
    display: flex;
}

/* Tags */
.project-tags-container {
    /* Fill all allotted space */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;

    /* Positioning and element wrapping*/
    white-space: nowrap;
    margin-right: 10px;
    margin-top: 3px;

    /* Clip scrollbar in .project-tags */
    overflow: hidden;
    height: 32px;
}

.project-tag-label {
    padding-top: 9px;
}

.project-tags {
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;

    height: 60px; /* Push scrollbar out of view */
}

.project-tag.MuiButtonBase-root {
    margin-left: 6px;
}

/* Social Icons */
.project-social {
    display: inline-block;
    float: right;
    white-space: nowrap;
}

.project-social a {
    padding: 4px;
}

.project-social a img {
    width: 32px;
}


/***** Media Queries *****/
@media (max-width: 480px) {
    .project-header {
        display: inline-block;
    }

    .project-title {
        width: 100%;
    }

    .project-date {
        float: left;
        text-align: left;
        margin-top: 2px;
    }

    .project-card:hover {
        -webkit-transform: none;
                transform: none;
    }
}


@media print {
    .project-card {
        box-shadow: none !important;
        border: 1px solid black;
        box-sizing: border-box;
        page-break-inside: avoid;
    }

    .project-image {
        display: none !important;
    }

    .project-date span {
        color: black;
    }

    .project-tag-label {
        color: black !important;
    }

    .project-tag {
        background-color: white !important;
        border: 1px solid black !important;
        box-sizing: border-box;
    }

    .project-social {
        display: none;
    }
}

.tag-filter {
    padding: 8px 15px;
    margin-bottom: 15px;
    height: 32px;

    position: -webkit-sticky;

    position: sticky;
    top: 65px;
    z-index: 10;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;

    transition:
        height 0.2s ease-in-out,
        padding 0.2s ease-in-out,
        margin 0.2s ease-in-out;
}

.tag-filter .tags {
    overflow-x: auto;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0px;
            flex-basis: 0px;
    white-space: nowrap;
}

.tag-filter.empty {
    height: 0px;

    padding-top: 0;
    padding-bottom: 0;

    margin-bottom: 0;
}

.tag-filter p {
    margin: 0;
    margin-right: 5px;

    color: #555;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

@media (max-width: 540px) {
    .tag-filter {
        top: 100px;
    }
}

#blog-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

#blog-container span.katex, #blog-content span.katex {
    font-size: 1em;
    margin: 0 0.05em;
    position: relative;
    top: 0.03em;
}

@media (max-width: 600px) {
    #blog-container .desktop, #blog-content .desktop {
        display: none !important;
    }
}

@media (min-width: 601px) {
    #blog-container .mobile, #blog-content .mobile {
        display: none !important;
    }
}

#content-container.blog {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

#blog-content {
    width: 95%;
    max-width: 700px;
    color: hsl(200, 10%, 15%);

    line-height: 1.5;
    font-family: 'EB Garamond', serif;
    font-size: 20px;
}

#blog-content .aux-info {
    font-size: 16px;
    color: hsl(200, 10%, 40%);
    margin: 0;
    text-align: center;
}

#blog-content .aux-info .date {
    margin-right: 3px;
}

#blog-content .aux-info .read-time {
    margin-left: 3px;
}

#blog-content img {
    max-width: 100%;
    margin: 25px auto;
    display: block;

    /* Alt-Text */
    color: hsl(200, 10%, 40%);
    text-align: center;
}

#blog-content h2, #blog-content h3, #blog-content h4 {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: hsl(200, 90%, 30%);
}

#blog-content code {
    color: hsl(200, 30%, 25%);
    font-size: 0.8em;

    margin: -0.2em 0.2em;
    padding: 0.15em 0.3em;

    background-color: hsl(200, 20%, 90%);
    border-radius: 0.3em;
    
}

#blog-content pre {
    font-size: 14px;
    line-height: 1.4;
}

#blog-content pre code {
    color: unset;
    font-size: unset;
    margin: unset;
    padding: unset;
    background-color: unset;
    border-radius: unset;
}

#blog-content .emphasis {
    text-align: center;
    background-color: hsl(200, 80%, 88%);
    border: 1px solid hsl(200, 80%, 85%);
    border-radius: 8px;
    padding: 15px 20px;
    font-size: 1.2em;
}

#blog-content .emphasis span.katex-display {
    margin: 0;
}

/** Lists **/
#blog-content ul, #blog-content ol {
    display: block;
    padding-left: 1.5em;
}

#blog-content ul.bullet-x, #blog-content ul.bullet-check {
    list-style-type: none;
}

#blog-content ul.bullet-x li::before {
    content: '\274C';
    margin-left: -30px;
    margin-right: 10px;
}

#blog-content ul.bullet-check li::before {
    content: '\2705';
    margin-left: -30px;
    margin-right: 10px;
}

/** Loading Icons **/
#blog-content .loading-placeholder {
    margin: 25px auto;
    text-align: center;
    color: hsl(200, 10%, 50%);
    font-size: 14px;
}

#blog-content .loading-error {
    text-align: center;
    color: hsl(350, 100%, 40%);
}


/***** Media Queries *****/
@media (max-width: 700px) {
    #blog-content {
        font-size: 18px;
    }

    #blog-content .aux-info {
        margin-top: 8px;
        font-size: 14px;
    }

    #blog-content .emphasis {
        padding: 10px 15px;
        font-size: 1.1em;
    }
}

@media print {
    #blog-content h2, #blog-content h3, #blog-content h4 {
        color: black;
    }

    #blog-content .aux-info {
        text-align: center;
        color: black;
    }

    #blog-content {
        color: black;
        font-size: 14px;
    }

    #blog-content a {
        color: black;
        text-decoration: none;
    }

    #blog-content .emphasis {
        background-color: white;
        border: 2px solid black;
    }

    #blog-content img {
        page-break-inside: avoid;
    }

    #blog-content code {
        padding: 0;
        margin: 0;
        background-color: none;
    }
}

/***** Project Cards *****/
a.blog-card {
    width: 95%;
    max-width: 800px;
    padding: 40px 50px;
    padding-bottom: 0;
    margin-top: 15px;
    margin-bottom: 40px;

    text-decoration: none;
    color: unset;
    display: block;

    border-radius: 0px 8px 8px 0px;

    background-color: white;
    border-left: 8px solid hsl(200, 60%, 30%);
    box-shadow: 1px 1px 3px hsla(200, 10%, 10%, 0.3);
}

.blog-card.loading {
    font-size: 24px;
    text-align: center;
    margin: 50px auto;
    font-family: 'EB Garamond', serif;
    color: hsl(200, 10%, 30%);
}

/*** Header ***/
.blog-title {
    font-family: Raleway, sans-serif;
    font-size: 30px;
    line-height: 1.2;
    color: hsl(200, 100%, 30%);
    font-weight: 500;
    text-overflow: ellipsis;

    margin: 0;
    margin-left: -3px;
}

.blog-subtitle {
    margin-top: 0;
    margin-bottom: 10px;
    font-family: Roboto, sans-serif;
    color: hsl(200, 10%, 55%);
    font-size: 12px;

    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
}

.blog-subtitle .date {
    margin-right: 8px;
}

.blog-subtitle .read-time {
    margin-left: 8px;
}

/*** Content ***/
.blog-info {
    font-size: 18px;
    font-family: 'EB Garamond', serif;
    line-height: 1.5;
    text-decoration: none;
    color: hsl(200, 10%, 10%);

    display: block;
    margin-bottom: 20px;
    padding-top: 1px;
    position: relative;

    cursor: pointer;

    max-height: 350px;
    overflow: hidden;
}

.blog-info img {
    display: block;
    max-width: 100%;
    margin: 10px auto;
}

.blog-info a.reference {
    display: none;
}

.blog-info-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, hsla(200, 10%, 100%, 0), hsl(200, 10%, 100%, 0.98) 85%, hsl(200, 10%, 100%));
    z-index: 1;
}


/* Tags */
.blog-tags {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;

    -webkit-align-items: center;

            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

    margin-top: 8px;
    margin-bottom: 5px;
}

.blog-tag {
    background-color: hsl(200, 30%, 97%);

    color: hsl(200, 20%, 50%);
    font-size: 12px;
    padding: 3px 8px;
    letter-spacing: 0.05em;
    margin-top: 6px;
    margin-right: 8px;

    white-space: nowrap;

    cursor: pointer;

    transition: background-color 0.15s ease-in-out;
}

.blog-tag:hover {
    background-color: hsl(200, 20%, 94%);
}


/***** Media Queries *****/
@media (max-width: 1000px) {
    a.blog-card {
        padding: 25px 28px;
        padding-bottom: 0;
        border-left: 6px solid hsl(200, 60%, 30%);
        
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .blog-title {
        font-size: 24px;
    }

    .blog-tags {
        margin-top: 6px;
    }

    .blog-tag {
        padding: 2px 6px;
    }
}

@media (max-width: 600px) {
    a.blog-card {
        margin-top: 0;
        margin-bottom: -20px;
        border: unset;

        box-shadow: unset;
        background-color: unset;
    }

    .blog-title {
        font-size: 20px;
        margin-left: -2px;
    }

    p.blog-subtitle {
        margin-bottom: 8px;
    }

    .blog-tags {
        display: none;
    }

    .blog-info {
        padding-top: 0;
        font-size: 16px;

        max-height: 200px;
    }

    .blog-info-cover {
        background-image: linear-gradient(to bottom, hsla(200, 10%, 96%, 0), hsl(200, 10%, 96%, 0.98) 85%, hsl(200, 10%, 96%));
    }
}


@media print {
    .blog-card {
        page-break-inside: avoid;
    }

    .blog-title, .blog-subtitle, .blog-info {
        color: black;
    }

    .blog-tag {
        background-color: white;
        border: 1px solid black;
        box-sizing: border-box;
        color: black;
    }

    .blog-info-cover {
        display: none;
    }
}

.about h2 {
    font-family: Raleway, sans-serif;
    font-weight: 300;
    margin-top: 70px;
    color: #477085;
}

