#blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#blog-container span.katex, #blog-content span.katex {
    font-size: 1em;
    margin: 0 0.05em;
    position: relative;
    top: 0.03em;
}

@media (max-width: 600px) {
    #blog-container .desktop, #blog-content .desktop {
        display: none !important;
    }
}

@media (min-width: 601px) {
    #blog-container .mobile, #blog-content .mobile {
        display: none !important;
    }
}
